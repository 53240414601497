/*** JQuery Page functions ***/

(function($, global) {
	var init;

	init =function() {
		/**
	     * Sets active and expands menu items based on id of body tag of current page
	     * e.g. <body id="body-index-page"> will result in the menu item with the id="navigation-index-page" having the
	     * class 'active' added, subsequently it will look for a child div with a class of collapse and add the class 'in'
	     * and set the height to auto
	     */
	    var body_id = $('body').attr('id');
		if (body_id != null) {
			var nav_element = $('#navigation-' + body_id.replace('body-',''));
	    	nav_element.addClass('active');
	    	if(nav_element.has('div.collapse')){
	    		var child_nav = nav_element.find('div.collapse');
	    		child_nav.addClass('in');
	    		child_nav.css('height: auto;');
	    	}
	    }

		//hide the top-stats when the arrow is clicked
	    var item = $('.top-stats');
	    var target = $('#hide-top-stats');
	    if(item.length > 0 && target.length > 0){
		        target.on('click', function() {
				item.css('position', 'relative');
				item.animate({
				    left: '-=1200',
	  		  	}, 1000, function() {
				    // Animation complete.
				    item.hide('slow');
				});
			});
		}

		$.fn.serializeObject = function() {
			var o = {};

			$.each(this.serializeArray(), function() {
				if (o[this.name] !== undefined) {
					if (!o[this.name].push) {
						o[this.name] = [o[this.name]];
					}
					o[this.name].push(this.value || '');
				} else {
					o[this.name] = this.value || '';
				}
			});

			return o;
		};
	}

	$(init);
})(jQuery, window);

$(document).ready(function() {

	$(".toggle-right").on("click",function() {
		$(this).find("i").toggleClass("fa-bars fa-close");
		$(".sub-nav").toggleClass("z-depth-1");
	});

	$(".sidebar-header i").on("click",function() {
		$(this).toggleClass("down");
	});

	$(".navbar-stacked li").on("click", function() {
		$(".navbar-stacked li").removeClass("active");
		$(this).toggleClass("active");
	});

	$("li.collapsible").on("click",function() {
		$(this).find(".fa.pull-right").toggleClass("down");
	});

	/*** Scroll to Top ***/
	$(window).scroll(function() {
		if ($(window).width() <= 576) {
			$(".scrollup").css("left","-99999px");
		} else {
			$(this).scrollTop() > 100 ? $(".scrollup").fadeIn() : $(".scrollup").fadeOut()
		}
	}),
	$(".scrollup").click(function() {
 		return $("html, body").animate({
 			scrollTop: 0
 		},600),!1
	 });

	$('body').on('click', '.hamburger', function () {
		this.classList.toggle('is-active');
	});

	$(".collapse-group").click(function(){
		$(this).find($(".rotate")).toggleClass("down");
	});

});